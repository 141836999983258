import React from 'react';
import { API, APIRequest, requestOptions } from 'variables/utils';
import ChannelList from './ChannelList';
import './chat.scss';
import MessagesPanel from './MessagePanel';
import socketClient from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize, faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import { generateKey } from 'variables/utils';
import Sound from 'react-sound';
import ChatForm from './ChatForm';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Col,

} from 'reactstrap';
//import chat_icon from 'assets/img/chat-bubble.png'
//https://dev.to/viclafouch/how-to-scope-your-css-scss-in-react-js-271a
import "assets/css/black-dashboard-react.scoped.css";
import "assets/scss/black-dashboard-react.scoped.scss";
import "./chat.scss"
//import "../../index.css"
//import { Buffer } from 'buffer';

class BubbleChatComponent extends React.Component {

    state = {
        playing: false,
        channels: null,
        socket: null,
        channel: null,
        chatToggle: false,
        hidden: false,
        activesound: "",
        deactivatesound: "none"
    }

    socket = null //socketClient(API, { transports: ['websocket', 'polling'] });

    componentDidMount() {
        //this.loadChannels();
        //this.configureSocket();
        //this.setState({
        //    channel: { ...this.props.channel, socket_id: this.socket.id }
        //})
    }

    createTicket = async (data) => {
        //let data = {
        //    platform: "Web"
        //}

        const ticketData = await APIRequest('/createticket/', { ...requestOptions, body: JSON.stringify(data) });

        this.setState({
            channel: { channel_id: ticketData.ticket_id, socket_id: this.socket.id },
            //channel_id: ticketData.ticket_id
        }, this.loadMessages)
    }

    componentWillUnmount() {
        //this.socket.emit('disconnect');
        this.socket.disconnect();
    }

    configureSocket = () => {
        let socket = socketClient(API, {
            transports: ['websocket', 'polling'],
            reconnectionAttempts: 10,
            timeout: 30000,
            reconnectionDelay: 5000
        });
        socket.on('connect', () => {
            console.log("new connection to socketIO", socket?.id)
            //if (this.state.channel) {
            //this.handleChannelSelect(this.state.channel.id);
            //}
        });
        socket.on('channel', channel => {
            this.setState({
                channel: { channel_id: channel?.channel_id } //...this.state.channel, 
            })

            // let channels = this.state.channels;
            // channels.forEach(c => {
            //     if (c.id === channel.id) {
            //         c.participants = channel.participants;
            //     }
            // });
            // this.setState({ channels });
            //console.log(this.state)
        });
        socket.on('message', message => {
            //console.log("message event data: ", message, "\nchannel state data: ", this.state.channel)
            if (this.state.channel?.channel_id === message?.channel_id) {
                if (!this.state.channel.messages) {
                    //this.state.channel.messages = [message];
                    this.setState({
                        channel: {
                            ...this.state.channel,
                            messages: [message]
                        }
                    })
                } else {
                    //console.log(message)
                    this.state.channel.messages.push(message);
                    //console.log(message.text)
                    if (message?.senderType !== 'User') {

                        if (this.state.activesound === "") {
                            this.setState({
                                playing: true
                            })
                        }else if (this.state.deactivatesound === ""){
                            this.setState({
                                playing: false
                            })
                        }
                    }
                    this.setState({
                        channel: {
                            ...this.state.channel,
                            messages: this.state.channel.messages
                        }
                    })
                }
            }

            // let channels = this.state.channels
            // channels.forEach(c => {
            //     if (c.id === message.channel_id) {
            //         if (!c.messages) {
            //             c.messages = [message];
            //         } else {
            //             c.messages.push(message);
            //         }
            //     }
            // });
            // this.setState({ channels });
        });
        socket.on('bot-message', message => {
            //if (this.state.channel.channel_id === message.channel_id) {
            if ((message?.socket_id && (this.socket.id === message.socket_id) || !message?.socket_id)) {
                if (!this.state.channel.messages) {
                    this.setState({
                        channel: {
                            ...this.state.channel,
                            messages: [message]
                        }
                    })
                } else {
                    this.state.channel.messages.push(message);
                    this.setState({
                        channel: {
                            ...this.state.channel,
                            messages: this.state.channel.messages
                        }
                    })
                }
            }

            // let channels = this.state.channels
            // channels.forEach(c => {
            //     if (c.id === message.channel_id) {
            //         if (!c.messages) {
            //             c.messages = [message];
            //         } else {
            //             // if (c.messages.at(-1).senderName === message.senderName) {
            //             //     c.messages.push( { ...message, senderName: undefined });    
            //             // } else {
            //             //     c.messages.push(message);
            //             // }
            //             c.messages.push(message);
            //         }
            //     }
            // });
            // this.setState({ channels });
        })
        this.socket = socket;
    }

    loadChannels = async () => {
        fetch(`${API}/getchannels`).then(async response => {
            let data = await response.json();
            this.setState({ channels: data.channels });
        })
    }

    loadMessages = async () => {
        let headers = new Headers()
        let userAccessToken = localStorage.getItem('USER_ACCESS_TOKEN')
        headers.append("Content-Type", "application/json");
        headers.append("x-access-token", userAccessToken);

        fetch(`${API}/getmessages`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ "ticket_id": this.state.channel?.channel_id })
        }).then(async response => {
            let data = await response.json();
            data.sort((prev, curr) => new Date(prev?.messages?.date) - new Date(curr?.messages?.date))
            let channel_messages = this.state?.channel?.messages || []
            //console.log("Messages Request Data: ", data)
            data.forEach(elem => {
                if (elem?.messages?.usermessage || elem?.messages?.usermediamessage || elem?.messagesagents?.usermediamessage) {
                    channel_messages.push({
                        id: generateKey(),
                        senderName: elem?.user?.username || "User",
                        text: elem?.messages?.usermessage,
                        mediaUrl: elem?.messages?.usermediamessage || elem?.messagesagents?.usermediamessage,
                        senderType: "User",
                        date: elem?.messages?.date
                    })
                }

                if (elem?.messagesagents && (elem?.messagesagents?.agentmessage || elem?.messagesagents?.agentmediamessage)) {
                    channel_messages.push({
                        id: generateKey(),
                        senderName: elem?.messagesagents?.chat_id, //"User",
                        text: elem?.messagesagents?.agentmessage,
                        mediaUrl: elem?.messagesagents?.agentmediamessage,
                        senderType: "System",
                        date: elem?.messages?.date
                    })
                }

                elem?.messages?.botmessage?.forEach(botmsg => {
                    if (botmsg?.response_type === "text") {
                        channel_messages.push({
                            id: generateKey(),
                            senderName: "ECCI ChatBot",
                            text: botmsg?.text,
                            mediaUrl: null,
                            senderType: "System",
                            date: elem?.messages?.date
                        })
                    } else if (botmsg?.response_type === "image") {
                        channel_messages.push({
                            id: generateKey(),
                            senderName: "ECCI ChatBot",
                            text: botmsg?.title,
                            mediaUrl: botmsg?.source,
                            senderType: "System",
                            date: elem?.messages?.date
                        })
                    } else if (botmsg?.response_type === "video") {
                        channel_messages.push({
                            id: generateKey(),
                            senderName: "ECCI ChatBot",
                            text: null,
                            mediaUrl: botmsg?.source,
                            senderType: "System",
                            date: elem?.messages?.date
                        })
                    }
                })
            })

            //console.log(`\n\nChatWorkspace Component state channel message before messagePanel: \n${this.props.channel.messages?.map(e => JSON.stringify(e)+ '\n\n')}\n\n`)
            this.setState({
                channel: {
                    ...this.state.channel,
                    messages: channel_messages
                }
            })
        })
    }

    handleChannelSelect = id => {
        let channel = this.state.channels.find(c => {
            return c.id === id;
        });
        this.setState({ channel });
        this.socket.emit('channel-join', id, ack => {
        });
    }

    handleSendMessage = async (channel_id, text) => {
        this.socket.emit('send-message', { date: new Date(), channel_id, socket_id: this.socket.id, text, senderName: localStorage.getItem('USER_NAME') || "Usuario"/**this.socket.id */, senderType: "User", id: Date.now() });
    }

    handleSendMedia = (channel_id, media) => {
        //let mediaFile = media.get("file")
        //const buf = Buffer.from([media])
        console.log("Media: ", media)
        try {
            console.log("try success")
            this.socket.emit('send-message', { date: new Date(), channel_id, socket_id: this.socket.id, media, senderName: localStorage.getItem('USER_NAME') || "Usuario"/**this.socket.id */, senderType: "User", id: Date.now() });
        } catch (e) {
            console.log("Error on sendMedia: ", e)
        }
    }

    closeToggleChat = () => {
        this.setState({ 
            chatToggle: !this.state.chatToggle, 
            hidden: false 
        })
    }

    toggleChat = () => {
        this.setState({ 
            chatToggle: !this.state.chatToggle, 
            hidden: true 
        })
    }

    activeSoundClick = () => {
        this.setState({
            activesound: "",
            deactivatesound: "none"
        })
    }

    deactivateSoundClick = () => {
        this.setState({
            activesound: "none",
            deactivatesound: ""
        })
    }

    MessageInitial = () => {
        this.handleSendMessage(this.state?.channel?.channel_id, "Hola")
    }

    render() {
        return <>
            <div className="chat-component">
                {/* <Button onClick={(e) => { this.setState({ chatToggle: !this.state.chatToggle }) }} */}
                <Sound
                    volume={60}
                    url="https://recursos-chatbot-ecci.s3.us-south.cloud-object-storage.appdomain.cloud/new_message.mp3"
                    playStatus={this.state.playing ? Sound.status.PLAYING : Sound.status.STOPPED}
                    playFromPosition={270 /* in milliseconds */}
                    autoLoad={true}
                    onError={(errorCode, description) => {
                        console.log("error playing sound: ", errorCode, description)
                    }}
                    //onLoading={this.handleSongLoading}
                    //onPlaying={this.handleSongPlaying}
                    onFinishedPlaying={() => {
                        this.setState({ playing: false })
                    }}
                />
                <Button className='chat-button' onClick={this.toggleChat}
                    title="Chatea con nosotros"
                    hidden={this.state.hidden}
                    style={{
                        borderRadius: "100%",
                        position: 'fixed',
                        bottom: '50px',
                        right: '20px',
                        minWidth: '0', //'7.5rem !important',
                        width: '7.5rem',
                        height: '6.5rem',
                        background: "#45b2db"
                    }}>
                    <img id="img-message" src='https://burbuja-web.s3.us-south.cloud-object-storage.appdomain.cloud//static/media/chat-bubble.634357c2a5ad1a7674d3.png' style={{ width: "2.5rem", height: "2.5rem" }} alt="icon chat" />
                </Button>
                <Collapse isOpen={this.state.chatToggle}>
                    <Card className="card-chart card-chat" style={{
                        display: "flex",
                        position: "fixed",
                        bottom: "0px",
                        right: "20px",
                    }}>
                        <CardHeader style={{ height: "35px" }}>
                            {/* <h5 className="card-category">Conversación</h5>
                        <CardTitle tag="h3">
                            <i className="tim-icons icon-single-02 text-primary" />{" "}
                            Usuario
                        </CardTitle> */}
                            <ul style={{ paddingInlineStart: "0px" }}>
                                <li style={{ display: "inline-block", float: "left" }}><img id="icon-bot" src="https://burbuja-web.s3.us-south.cloud-object-storage.appdomain.cloud//static/media/Logo.8fdfb6b683defaeec6f0.png" /></li>
                                <li style={{ display: "inline-block", float: "left" }}><h5 id="title-bubble">ChatBot ECCI</h5></li>
                                <li style={{ display: "inline-block", float: "right" }}><FontAwesomeIcon onClick={this.closeToggleChat} id="icon-minimize" icon={faWindowMinimize} /></li>
                                <li style={{display:"inline-block", float:"right", marginRight:"1.5rem"}}>
                                <FontAwesomeIcon id='volumeHigh' icon={faVolumeHigh} style={{display:this.state.activesound, color:"black", cursor:"pointer"}} onClick={this.deactivateSoundClick}/>
                                <FontAwesomeIcon id='volumeMinimize' icon={faVolumeXmark} style={{display:this.state.deactivatesound, color:"black", cursor:"pointer"}} onClick={this.activeSoundClick}/></li>
                            </ul>
                        </CardHeader>
                        <CardBody className='pb-0'>
                            <div className="chat-area">
                                <div className="chat-app chat-bubble">
                                    {/* <ChannelList channels={this.state.channels} onSelectChannel={this.handleChannelSelect} /> */}
                                    {/*console.log("Component Channel: ", this.state.channel)*/} {/** LOG TEMPORAL PARA IDENTIFICAR CHANNEL OBJECT */}
                                    {
                                        this.state?.channel?.channel_id ?
                                            <MessagesPanel message={this.MessageInitial} onSendMessage={this.handleSendMessage} onSendMedia={this.handleSendMedia} channel={this.state.channel} /> :
                                            <ChatForm configureSocket={this.configureSocket} onSendMessage={this.handleSendMessage} handleCreateTicket={this.createTicket} channel={this.state.channel} />
                                    }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        </>;
    }
}

export default BubbleChatComponent;