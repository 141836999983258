import React from 'react';
import Message from './Message';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import VoiceRecorderInput from './VoiceRecorderInput';
import { Input, Container, Row, Col, Button, Label } from 'reactstrap';
import "assets/css/black-dashboard-react.scoped.css";
import "assets/scss/black-dashboard-react.scoped.scss";

const listRef = React.createRef();

class MessagesPanel extends React.Component {
    state = {
        input_value: '',
        file: null,
        chat: true
    }

    send = () => {
        if (document.getElementById('text-input').value !== '') {
            //console.log("ID on SEND: ", this.props.channel)
            let input_text = document.getElementById('text-input').value
            this.props.onSendMessage(this.props.channel.channel_id, input_text);
            setTimeout(() => {
                document.getElementById('text-input').value = ''
            }, 40);
            this.goScrollDown()
            //this.props.onSendMessage(this.props.channel.channel_id, this.state.input_value);
            //this.setState({ input_value: '' });
            //console.log("Channel props messagepanel component: ", typeof (this.props.channel.messages), this.props.channel.messages)
        }
    }

    handleInput = e => {
        this.setState({ input_value: e.target.value });
    }

    handleEnter = async e => {
        if ((e.code === "Enter" || e.code === "NumpadEnter" || e.code === "Space")) {
            if (document.getElementById('text-input').value === "") {
                e.preventDefault()
            }
        }

        if (e.ctrlKey && (e.code === "Enter" || e.code === "NumpadEnter")) {
            if (document.getElementById('text-input').value !== "") {
                document.getElementById("text-input").value += '\n';
            }
        } else if (e.code === "Enter" || e.code === "NumpadEnter") {
            if (document.getElementById('text-input').value !== "") {
                this.send()
            }
        }
    }

    saveFile = (e) => {
        this.setState({
            file: {
                name: e.target.files[0].name,
                file: e.target.files[0]
            }
        }, this.uploadFile)
    };

    uploadFile = async (e) => {
        // const formData = new FormData();
        // formData.append("file", this.state.file.file);
        // formData.append("fileName", this.state.file.name);
        // console.log("formData: ", formData.get('file'), " - ", typeof(formData.get("file")))

        try {
            // let reader = new FileReader();
            // let chunk = reader.readAsArrayBuffer(this.state.file.file)
            // reader.addEventListener("loadend", () => {
            //     console.log("Result: ", reader.result)
            //     let arr8 = new Uint8Array(reader.result)
            //     console.log("Array: ", arr8)
            //     this.props.onSendMedia(this.props.channel.id, arr8);
            //     console.log("onSendMedia success.")
            // })


            //console.log("Reader Info: ", reader.readyState, " ", reader.result)
            //console.log("\nChunk arraybuffer: ", chunk)
            this.props.onSendMedia(this.props.channel.id, this.state.file);
            //console.log("onSendMedia success.")
        } catch (err) {
            console.log("Error en envio multimedia: ", err);
        }
    };

    sendVoice = async (audio) => {
        try {
            console.log('\n SendVoice on MessagePanel!!')
            this.props.onSendMedia(this.props.channel.id, audio); //this.state.file
            //console.log("onSendMedia success.")
        } catch (err) {
            console.log("Error en envio multimedia: ", err);
        }
    }

    rowData = ({ index, style }) => (
        <div style={{
            ...style,
            textAlign: this.props.channel.messages[index].senderType === "User" ? "right" : "left",
            //paddingTop: this.props.channel.messages[index - 1]?.senderName !== this.props.channel.messages[index].senderName ? "0px" : "20px"
        }}> {/**className={index % 2 ? "ListItemOdd" : "ListItemEven"} */}
            <div className='message-item' style={{
                backgroundColor: this.props.channel.messages[index].senderType === "User" ? "#bfd6fa" : "#f3f0ec",
            }}>
                <Message
                    key={this.props.channel.messages[index].id} id={this.props.channel.messages[index].id}
                    senderName={this.props.channel.messages[index - 1]?.senderName !== this.props.channel.messages[index].senderName ? this.props.channel.messages[index].senderName : undefined}
                    senderType={this.props.channel.messages[index].senderType}
                    text={this.props.channel.messages[index]?.text} mediaUrl={this.props.channel.messages[index]?.mediaUrl}
                    date={this.props.channel.messages[index]?.date}>
                </Message>
            </div>
        </div>
    );

    estimateItemSize = (index) => {
        if (this.props.channel.messages[index]?.mediaUrl) {
            //console.log("enter to estimateItemSize mediaUrl", this.props.channel.messages[index]?.mediaUrl?.split('.')?.at(-1))
            //if (this.props.channel.messages[index]?.mediaUrl?.split(".")?.at(-1) )
            switch (this.props.channel.messages[index]?.mediaUrl?.split('.')?.at(-1)) {
                case 'mpeg':
                    return 150;
                case 'jpg':
                case 'jpeg':
                    return 600;
                case 'pdf':
                    return 270;
                default:
                    return 250;
            }
        } else if (this.props.channel.messages[index]?.text) {
            let lineCount = this.props.channel.messages[index]?.text.split(/\r\n|\r|\n/).length
            console.log("enter to estimateItemSize message text with lineCount:", lineCount, this.props.channel.messages[index]?.text)
            //console.log("Text \n", this.props.channel.messages[index]?.text)
            //console.log("lines count ", lineCount)
            //if (this.props.channel.messages[index]?.text.length < 50) return 50
            //return (Number.parseInt(this.props.channel.messages[index]?.text.length / 50) + 1) * 50
            if (lineCount === 1 && this.props.channel.messages[index]?.text.length > 150) {
                //console.log("window width", window.screen.availWidth)
                console.log("enter to message with 1 line", this.props.channel.messages[index]?.text.length)
                return (this.props.channel.messages[index]?.text.length / 50) * 35
            }
            return (lineCount + 1.8) * 35
        } else {
            //console.log("enter to else in estimateItemSize")
            return 200
        }
    }

    tmpRender = () => {
        // <div className="messages-list">{list}</div>
        {
            this.props.channel &&
                <div className="messages-input">
                    <Container fluid>
                        <Row>
                            <input type="text" onKeyPress={this.handleEnter} value={this.state.input_value} />
                        </Row>
                        <Row className="image-upload" style={{ justifyContent: "space-between" }}>
                            <Col>
                                <input id="file-input" style={{ "display": "none" }} type="file" accept='*/*' onChange={this.saveFile} />
                                <label htmlFor="file-input" style={{ "cursor": "pointer", "paddingTop": "50%" }}>
                                    <i className="tim-icons icon-attach-87 black-states" />
                                </label>
                            </Col>
                            <Col>
                                <VoiceRecorderInput sendVoice={this.sendVoice} />
                            </Col>
                            <Col>
                                <button onClick={this.send}>Send</button>
                            </Col>
                        </Row>
                    </Container>
                </div>
        }
    }

    goScrollDown = () => {
        //https://www.robinwieruch.de/react-scroll-to-item/
        let list = document.getElementById("messagesList");
        list?.lastChild?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        })
    }

    messagesList = () => {
        return <>
            {/* {console.log("height", height)} */}
            {
                (this.props.channel && this.props.channel.messages) &&
                this?.props?.channel?.messages?.map((message, index) => {
                    return <li key={index} className="p-1 text-sm" style={{
                        color: '#000',
                        textAlign: this.props.channel.messages[index].senderType === "User" ? "right" : "left",
                        fontSize: '0.875rem',
                        //lineHeight: '1.25rem'
                    }}>
                        <div className='message-item' style={{
                            backgroundColor: this.props.channel.messages[index].senderType === "User" ? "#bfd6fa" : "#f3f0ec",
                        }}>
                            <Message
                                key={this.props.channel.messages[index].id} id={this.props.channel.messages[index].id}
                                senderName={this.props.channel.messages[index - 1]?.senderName !== this.props.channel.messages[index].senderName ? this.props.channel.messages[index].senderName : undefined}
                                senderType={this.props.channel.messages[index].senderType}
                                text={this.props.channel.messages[index]?.text} mediaUrl={this.props.channel.messages[index]?.mediaUrl}
                                date={this.props.channel.messages[index]?.date}>
                            </Message>
                        </div>
                    </li>
                })
            }
            {
                (this.props.channel && !this.props.channel.messages) &&
                <div className="no-content-message">There is no messages to show</div>
            }
        </>
    }

    componentDidMount() {
        this.goScrollDown()
    }

    componentDidUpdate() {
        this.goScrollDown()
    }

    render() {

        let list = <div className="no-content-message">There is no messages to show</div>;

        if (this.props.channel.messages < 1) {
            this.props.message()
        }

        if (this.props.channel && this.props.channel.messages) {
            //console.log("Channel props messagepanel component: ", typeof (this.props.channel.messages), this.props.channel.messages)
            list = this.props.channel.messages.map(m => <Message key={m.id} id={m.id}
                senderName={this.props.channel.messages[this.props.channel.messages.indexOf(m) - 1]?.senderName !== m.senderName ? m.senderName : undefined}
                text={m?.text} mediaUrl={m.mediaUrl}></Message>);
        }
        return <>
            <div className="messages-panel">
                {/* <List
                    width={600}
                    height={600}
                    rowHeight={50}
                    rowCount={this.props.channel.messages.length}
                    rowRenderer={(key, index, style, parent) => {
                        const message = list[index]

                        return <div key={key} style={style}>
                            {message}
                        </div>
                    }}
                /> */}
                <ul id='messagesList' className='messages-list' style={{
                    overflowY: "auto",
                    listStyle: "none",
                    listStyleType: "none",
                    paddingLeft: 0
                }}>
                    {this.messagesList()}
                </ul>
                {
                    this.props.channel &&
                    <div className="messages-input">
                        <Container fluid className='mr-3'>
                            <Row>
                                <Col md={10} xs={8}>
                                    <textarea type="text" id='text-input' style={{ wordBreak: 'break-word', width: "100%", height: "50px", borderRadius: "10px", paddingLeft: "3px", paddingTop: "3px", resize: "none" }} onKeyPress={this.handleEnter} />
                                </Col>
                                <label onClick={this.send} id='message-send' style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    color: "white",
                                    //display: "none",
                                    "fontSize": "16px", "fontWeight": "bold", "textAlign": "center", "width": "40px", "height": "40px", "background": "#212245", borderRadius: "100%", marginTop: "2px",
                                }}>
                                    <FontAwesomeIcon icon={faPaperPlane} title="Start recording" style={{ fontSize: "18px", color: "white", marginTop: "10px" }} />
                                </label>

                                {/* <Col style={{ textAlign: 'left' }}>
                                    <section className="image-upload">
                                        <input id="file-input" style={{ "display": "none" }} type="file" accept='' onChange={this.saveFile} />
                                         <label htmlFor="file-input" style={{ "cursor": "pointer" }}>
                                    <i className="tim-icons icon-attach-87 black-states" />
                                </label> 
                                    </section>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                     <VoiceRecorderInput sendVoice={this.sendVoice} />
                                </Col> */}
                            </Row>


                            {/*
                            <Row style={{marginTop: "10px"}}>
                                <Input id='checkbox' type='checkbox' style={{ width: "10%" }} />{' '}
                                <Label style={{ color: "black", marginLeft: "53px", fontSize: "1rem", marginTop: "4px" }}>
                                    Alerta de Sonido
                                </Label>
                            </Row>
                            */}

                        </Container>
                    </div>
                }
            </div>
        </>
    }

}

export default MessagesPanel;